<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="85"
  >
    <v-container class="px-0 text-right d-flex align-center">
      <v-toolbar-title
        class="font-weight-light hidden-xs-only"
        v-text="title"
      />

      <v-spacer />
      <div v-for="(item, i) in items" :key="i">
        <v-btn
          v-if="item.require === null || (item.require === 'FrogAdmin' && isFrogAdmin)"
          :to="item.to"
          min-height="48"
          min-width="40"
          text
        >
          <v-icon
            :left="$vuetify.breakpoint.mdAndUp"
            size="20"
            v-text="item.icon"
          />

          <span
            class="hidden-sm-and-down"
            v-text="item.text"
          />
        </v-btn>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PagesCoreAppBar',

  data: () => ({
    items: [
      {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        to: '/',
        require: null
      },
      {
        icon: 'mdi-clipboard-outline',
        text: 'Listings',
        to: '/listings',
        require: 'FrogAdmin'
      },
      {
        icon: 'mdi-play-box-multiple-outline',
        text: 'Videos',
        to: '/videos',
        require: 'FrogAdmin'
      },
      {
        icon: 'mdi-clipboard-outline',
        text: 'Resources',
        to: '/resources',
        require: 'FrogAdmin'
      }
      // {
      //   icon: 'mdi-account',
      //   text: 'Profile',
      //   to: 'user',
      //   require: null
      // }
    ],
    titles: {}
  }),

  computed: {
    title () {
      return this.titles[this.$route.path]
    },
    isFrogAdmin () {
      const userRole = []
      this.$auth.user['https://userrolesfrog.admin/user_authorization'].roles.forEach(element => {
        userRole.push(element)
      })
      if (userRole.includes('Frog Admin')) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
